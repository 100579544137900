/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProductInfoTags = {
    type: 'array',
    contains: {
        type: 'Enum',
    },
} as const;
