/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type JackpotInfo = {
    code: JackpotInfo.code;
    prevAmount: number;
    amount: number;
    updatedAt: number;
    lastWinAt: number;
    endAt: string;
};
export namespace JackpotInfo {
    export enum code {
        MINI = 'mini',
        MINOR = 'minor',
        MAJOR = 'major',
        GRAND = 'grand',
    }
}

