/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { Api } from './Api';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AcceptDailyBonusRequest } from './models/AcceptDailyBonusRequest';
export type { AcceptDailyBonusResponse } from './models/AcceptDailyBonusResponse';
export type { AcceptFreeGoldCoinsRequest } from './models/AcceptFreeGoldCoinsRequest';
export type { AcceptFreeGoldCoinsResponse } from './models/AcceptFreeGoldCoinsResponse';
export type { AcceptInvitationBonusRequest } from './models/AcceptInvitationBonusRequest';
export type { AcceptInvitationBonusResponse } from './models/AcceptInvitationBonusResponse';
export type { AcceptManualBonusRequest } from './models/AcceptManualBonusRequest';
export type { AcceptManualBonusResponse } from './models/AcceptManualBonusResponse';
export type { AcceptPersonalJackpotRequest } from './models/AcceptPersonalJackpotRequest';
export type { AcceptPersonalJackpotResponse } from './models/AcceptPersonalJackpotResponse';
export type { AcceptRulesRequest } from './models/AcceptRulesRequest';
export type { AcceptRulesResponse } from './models/AcceptRulesResponse';
export type { AcceptSweepstakeIntroRequest } from './models/AcceptSweepstakeIntroRequest';
export type { AcceptSweepstakeIntroResponse } from './models/AcceptSweepstakeIntroResponse';
export type { AccountBalanceInfo } from './models/AccountBalanceInfo';
export type { AccountInfo } from './models/AccountInfo';
export type { AccountInvitations } from './models/AccountInvitations';
export type { AccountJackpotInfo } from './models/AccountJackpotInfo';
export type { AccountJackpotWin } from './models/AccountJackpotWin';
export type { AccountPersonalJackpotContribution } from './models/AccountPersonalJackpotContribution';
export type { AccountRestrictions } from './models/AccountRestrictions';
export { AccountsMode } from './models/AccountsMode';
export type { AddAccountFavouriteGameRequest } from './models/AddAccountFavouriteGameRequest';
export type { AddAccountFavouriteGameResponse } from './models/AddAccountFavouriteGameResponse';
export type { AppliedRules } from './models/AppliedRules';
export type { BannerInfo } from './models/BannerInfo';
export type { brandName } from './models/brandName';
export type { CashbackBonusInfo } from './models/CashbackBonusInfo';
export type { ChangePasswordRequest } from './models/ChangePasswordRequest';
export type { ChangePasswordResponse } from './models/ChangePasswordResponse';
export type { ChangeSubscriptionPreferencesRequest } from './models/ChangeSubscriptionPreferencesRequest';
export type { ChangeSubscriptionPreferencesResponse } from './models/ChangeSubscriptionPreferencesResponse';
export type { ChatDepartment } from './models/ChatDepartment';
export type { ConfirmPhoneNumberVerificationRequest } from './models/ConfirmPhoneNumberVerificationRequest';
export type { ConfirmPhoneNumberVerificationResponse } from './models/ConfirmPhoneNumberVerificationResponse';
export type { CountryAndStates } from './models/CountryAndStates';
export type { CreatePhoneNumberVerificationRequest } from './models/CreatePhoneNumberVerificationRequest';
export type { CreatePhoneNumberVerificationResponse } from './models/CreatePhoneNumberVerificationResponse';
export type { CreatePostalCodeRequest } from './models/CreatePostalCodeRequest';
export type { CreatePostalCodeResponse } from './models/CreatePostalCodeResponse';
export { Currency } from './models/Currency';
export type { DailyBonusInfo } from './models/DailyBonusInfo';
export type { ErrorResponse } from './models/ErrorResponse';
export type { FreeSpinInfo } from './models/FreeSpinInfo';
export { GameTypes } from './models/GameTypes';
export type { GetAccountBonusesRequest } from './models/GetAccountBonusesRequest';
export type { GetAccountBonusesResponse } from './models/GetAccountBonusesResponse';
export type { GetAccountFreeSpinsRequest } from './models/GetAccountFreeSpinsRequest';
export type { GetAccountFreeSpinsResponse } from './models/GetAccountFreeSpinsResponse';
export type { GetAccountInfoRequest } from './models/GetAccountInfoRequest';
export type { GetAccountInfoResponse } from './models/GetAccountInfoResponse';
export type { GetAccountInvitationsRequest } from './models/GetAccountInvitationsRequest';
export type { GetAccountInvitationsResponse } from './models/GetAccountInvitationsResponse';
export type { GetAccountJackpotInfoRequest } from './models/GetAccountJackpotInfoRequest';
export type { GetAccountJackpotInfoResponse } from './models/GetAccountJackpotInfoResponse';
export type { GetAccountSettingsRequest } from './models/GetAccountSettingsRequest';
export type { GetAccountSettingsResponse } from './models/GetAccountSettingsResponse';
export type { GetBannersRequest } from './models/GetBannersRequest';
export type { GetBannersResponse } from './models/GetBannersResponse';
export type { GetFreeGoldCoinsStatusRequest } from './models/GetFreeGoldCoinsStatusRequest';
export type { GetFreeGoldCoinsStatusResponse } from './models/GetFreeGoldCoinsStatusResponse';
export type { GetJackpotInfoRequest } from './models/GetJackpotInfoRequest';
export type { GetJackpotInfoResponse } from './models/GetJackpotInfoResponse';
export type { GetJackpotOptInRequest } from './models/GetJackpotOptInRequest';
export type { GetJackpotOptInResponse } from './models/GetJackpotOptInResponse';
export type { GetJackpotTotalsRequest } from './models/GetJackpotTotalsRequest';
export type { GetJackpotTotalsResponse } from './models/GetJackpotTotalsResponse';
export type { GetKYCInfoRequest } from './models/GetKYCInfoRequest';
export type { GetKYCInfoResponse } from './models/GetKYCInfoResponse';
export type { GetLiveChatSettingsRequest } from './models/GetLiveChatSettingsRequest';
export type { GetLiveChatSettingsResponse } from './models/GetLiveChatSettingsResponse';
export type { GetLotteryResultsRequest } from './models/GetLotteryResultsRequest';
export type { GetLotteryResultsResponse } from './models/GetLotteryResultsResponse';
export type { GetOptInProductsRequest } from './models/GetOptInProductsRequest';
export type { GetOptInProductsResponse } from './models/GetOptInProductsResponse';
export type { GetPersonalJackpotRequest } from './models/GetPersonalJackpotRequest';
export type { GetPersonalJackpotResponse } from './models/GetPersonalJackpotResponse';
export type { GetSignupCountriesAndStatesRequest } from './models/GetSignupCountriesAndStatesRequest';
export type { GetSignupCountriesAndStatesResponse } from './models/GetSignupCountriesAndStatesResponse';
export type { GetSignupCountriesRequest } from './models/GetSignupCountriesRequest';
export type { GetSignupCountriesResponse } from './models/GetSignupCountriesResponse';
export type { GetSkipOtpCheckRequest } from './models/GetSkipOtpCheckRequest';
export type { GetSkipOtpCheckResponse } from './models/GetSkipOtpCheckResponse';
export type { GetTemporaryTokenRequest } from './models/GetTemporaryTokenRequest';
export { GetTemporaryTokenResponse } from './models/GetTemporaryTokenResponse';
export type { GetVisitedPromotionsRequest } from './models/GetVisitedPromotionsRequest';
export type { GetVisitedPromotionsResponse } from './models/GetVisitedPromotionsResponse';
export type { GoldCoinGeneratorSettings } from './models/GoldCoinGeneratorSettings';
export type { InvitationBonusInfo } from './models/InvitationBonusInfo';
export type { InvitationInfo } from './models/InvitationInfo';
export type { InvitationRewardPolicy } from './models/InvitationRewardPolicy';
export type { JackpotAccountFreeContribution } from './models/JackpotAccountFreeContribution';
export { JackpotInfo } from './models/JackpotInfo';
export type { JackpotPreferences } from './models/JackpotPreferences';
export type { JsonNode } from './models/JsonNode';
export type { KYCInfo } from './models/KYCInfo';
export { KYCStatus } from './models/KYCStatus';
export type { LegalRule } from './models/LegalRule';
export { LegalRuleSettings } from './models/LegalRuleSettings';
export { LevelInfo } from './models/LevelInfo';
export type { LocationInfo } from './models/LocationInfo';
export type { LowOnCoinsRequest } from './models/LowOnCoinsRequest';
export type { LowOnCoinsResponse } from './models/LowOnCoinsResponse';
export type { ManualBonusInfo } from './models/ManualBonusInfo';
export type { OtpModalTriggeredRequest } from './models/OtpModalTriggeredRequest';
export type { OtpModalTriggeredResponse } from './models/OtpModalTriggeredResponse';
export type { PasswordEnforcementPolicy } from './models/PasswordEnforcementPolicy';
export type { PersonalInfo } from './models/PersonalInfo';
export type { platform } from './models/platform';
export { ProductInfo } from './models/ProductInfo';
export type { ProductInfoTags } from './models/ProductInfoTags';
export { ProductModes } from './models/ProductModes';
export { Promo } from './models/Promo';
export type { qBrandName } from './models/qBrandName';
export { qPlatform } from './models/qPlatform';
export type { RedeemMoneyPolicy } from './models/RedeemMoneyPolicy';
export type { RemoveAccountFavouriteGameRequest } from './models/RemoveAccountFavouriteGameRequest';
export type { RemoveAccountFavouriteGameResponse } from './models/RemoveAccountFavouriteGameResponse';
export type { ResendEmailVerificationRequest } from './models/ResendEmailVerificationRequest';
export type { ResendEmailVerificationResponse } from './models/ResendEmailVerificationResponse';
export type { ResetPasswordManuallyRequest } from './models/ResetPasswordManuallyRequest';
export type { ResetPasswordManuallyResponse } from './models/ResetPasswordManuallyResponse';
export { RuleType } from './models/RuleType';
export type { SaveJackpotOptInRequest } from './models/SaveJackpotOptInRequest';
export type { SaveJackpotOptInResponse } from './models/SaveJackpotOptInResponse';
export type { SendSignInByTokenEmailRequest } from './models/SendSignInByTokenEmailRequest';
export type { SendSignInByTokenEmailResponse } from './models/SendSignInByTokenEmailResponse';
export type { SendWelcomeEmailRequest } from './models/SendWelcomeEmailRequest';
export type { SendWelcomeEmailResponse } from './models/SendWelcomeEmailResponse';
export type { SetAccountAttributionInfoRequest } from './models/SetAccountAttributionInfoRequest';
export type { SetAccountAttributionInfoResponse } from './models/SetAccountAttributionInfoResponse';
export type { SetAccountInfoRequest } from './models/SetAccountInfoRequest';
export type { SetAccountInfoResponse } from './models/SetAccountInfoResponse';
export type { SetAccountModeRequest } from './models/SetAccountModeRequest';
export type { SetAccountModeResponse } from './models/SetAccountModeResponse';
export type { SetKYCInfoRequest } from './models/SetKYCInfoRequest';
export { SetKYCInfoResponse } from './models/SetKYCInfoResponse';
export type { SetLocationInfoRequest } from './models/SetLocationInfoRequest';
export type { SetLocationInfoResponse } from './models/SetLocationInfoResponse';
export type { SetPushInfoRequest } from './models/SetPushInfoRequest';
export type { SetPushInfoResponse } from './models/SetPushInfoResponse';
export type { UpdateKYCInfoRequest } from './models/UpdateKYCInfoRequest';
export type { UpdateKYCInfoResponse } from './models/UpdateKYCInfoResponse';
export type { UpdateVisitedPromotionsRequest } from './models/UpdateVisitedPromotionsRequest';
export type { UpdateVisitedPromotionsResponse } from './models/UpdateVisitedPromotionsResponse';
export type { X_Chk } from './models/X_Chk';
export type { X_Message_Id } from './models/X_Message_Id';
export type { X_Timestamp } from './models/X_Timestamp';
export type { xMessageId } from './models/xMessageId';
export { xStatus } from './models/xStatus';
export type { xStatusText } from './models/xStatusText';
export type { xTook } from './models/xTook';

export { $AcceptDailyBonusRequest } from './schemas/$AcceptDailyBonusRequest';
export { $AcceptDailyBonusResponse } from './schemas/$AcceptDailyBonusResponse';
export { $AcceptFreeGoldCoinsRequest } from './schemas/$AcceptFreeGoldCoinsRequest';
export { $AcceptFreeGoldCoinsResponse } from './schemas/$AcceptFreeGoldCoinsResponse';
export { $AcceptInvitationBonusRequest } from './schemas/$AcceptInvitationBonusRequest';
export { $AcceptInvitationBonusResponse } from './schemas/$AcceptInvitationBonusResponse';
export { $AcceptManualBonusRequest } from './schemas/$AcceptManualBonusRequest';
export { $AcceptManualBonusResponse } from './schemas/$AcceptManualBonusResponse';
export { $AcceptPersonalJackpotRequest } from './schemas/$AcceptPersonalJackpotRequest';
export { $AcceptPersonalJackpotResponse } from './schemas/$AcceptPersonalJackpotResponse';
export { $AcceptRulesRequest } from './schemas/$AcceptRulesRequest';
export { $AcceptRulesResponse } from './schemas/$AcceptRulesResponse';
export { $AcceptSweepstakeIntroRequest } from './schemas/$AcceptSweepstakeIntroRequest';
export { $AcceptSweepstakeIntroResponse } from './schemas/$AcceptSweepstakeIntroResponse';
export { $AccountBalanceInfo } from './schemas/$AccountBalanceInfo';
export { $AccountInfo } from './schemas/$AccountInfo';
export { $AccountInvitations } from './schemas/$AccountInvitations';
export { $AccountJackpotInfo } from './schemas/$AccountJackpotInfo';
export { $AccountJackpotWin } from './schemas/$AccountJackpotWin';
export { $AccountPersonalJackpotContribution } from './schemas/$AccountPersonalJackpotContribution';
export { $AccountRestrictions } from './schemas/$AccountRestrictions';
export { $AccountsMode } from './schemas/$AccountsMode';
export { $AddAccountFavouriteGameRequest } from './schemas/$AddAccountFavouriteGameRequest';
export { $AddAccountFavouriteGameResponse } from './schemas/$AddAccountFavouriteGameResponse';
export { $AppliedRules } from './schemas/$AppliedRules';
export { $BannerInfo } from './schemas/$BannerInfo';
export { $brandName } from './schemas/$brandName';
export { $CashbackBonusInfo } from './schemas/$CashbackBonusInfo';
export { $ChangePasswordRequest } from './schemas/$ChangePasswordRequest';
export { $ChangePasswordResponse } from './schemas/$ChangePasswordResponse';
export { $ChangeSubscriptionPreferencesRequest } from './schemas/$ChangeSubscriptionPreferencesRequest';
export { $ChangeSubscriptionPreferencesResponse } from './schemas/$ChangeSubscriptionPreferencesResponse';
export { $ChatDepartment } from './schemas/$ChatDepartment';
export { $ConfirmPhoneNumberVerificationRequest } from './schemas/$ConfirmPhoneNumberVerificationRequest';
export { $ConfirmPhoneNumberVerificationResponse } from './schemas/$ConfirmPhoneNumberVerificationResponse';
export { $CountryAndStates } from './schemas/$CountryAndStates';
export { $CreatePhoneNumberVerificationRequest } from './schemas/$CreatePhoneNumberVerificationRequest';
export { $CreatePhoneNumberVerificationResponse } from './schemas/$CreatePhoneNumberVerificationResponse';
export { $CreatePostalCodeRequest } from './schemas/$CreatePostalCodeRequest';
export { $CreatePostalCodeResponse } from './schemas/$CreatePostalCodeResponse';
export { $Currency } from './schemas/$Currency';
export { $DailyBonusInfo } from './schemas/$DailyBonusInfo';
export { $ErrorResponse } from './schemas/$ErrorResponse';
export { $FreeSpinInfo } from './schemas/$FreeSpinInfo';
export { $GameTypes } from './schemas/$GameTypes';
export { $GetAccountBonusesRequest } from './schemas/$GetAccountBonusesRequest';
export { $GetAccountBonusesResponse } from './schemas/$GetAccountBonusesResponse';
export { $GetAccountFreeSpinsRequest } from './schemas/$GetAccountFreeSpinsRequest';
export { $GetAccountFreeSpinsResponse } from './schemas/$GetAccountFreeSpinsResponse';
export { $GetAccountInfoRequest } from './schemas/$GetAccountInfoRequest';
export { $GetAccountInfoResponse } from './schemas/$GetAccountInfoResponse';
export { $GetAccountInvitationsRequest } from './schemas/$GetAccountInvitationsRequest';
export { $GetAccountInvitationsResponse } from './schemas/$GetAccountInvitationsResponse';
export { $GetAccountJackpotInfoRequest } from './schemas/$GetAccountJackpotInfoRequest';
export { $GetAccountJackpotInfoResponse } from './schemas/$GetAccountJackpotInfoResponse';
export { $GetAccountSettingsRequest } from './schemas/$GetAccountSettingsRequest';
export { $GetAccountSettingsResponse } from './schemas/$GetAccountSettingsResponse';
export { $GetBannersRequest } from './schemas/$GetBannersRequest';
export { $GetBannersResponse } from './schemas/$GetBannersResponse';
export { $GetFreeGoldCoinsStatusRequest } from './schemas/$GetFreeGoldCoinsStatusRequest';
export { $GetFreeGoldCoinsStatusResponse } from './schemas/$GetFreeGoldCoinsStatusResponse';
export { $GetJackpotInfoRequest } from './schemas/$GetJackpotInfoRequest';
export { $GetJackpotInfoResponse } from './schemas/$GetJackpotInfoResponse';
export { $GetJackpotOptInRequest } from './schemas/$GetJackpotOptInRequest';
export { $GetJackpotOptInResponse } from './schemas/$GetJackpotOptInResponse';
export { $GetJackpotTotalsRequest } from './schemas/$GetJackpotTotalsRequest';
export { $GetJackpotTotalsResponse } from './schemas/$GetJackpotTotalsResponse';
export { $GetKYCInfoRequest } from './schemas/$GetKYCInfoRequest';
export { $GetKYCInfoResponse } from './schemas/$GetKYCInfoResponse';
export { $GetLiveChatSettingsRequest } from './schemas/$GetLiveChatSettingsRequest';
export { $GetLiveChatSettingsResponse } from './schemas/$GetLiveChatSettingsResponse';
export { $GetLotteryResultsRequest } from './schemas/$GetLotteryResultsRequest';
export { $GetLotteryResultsResponse } from './schemas/$GetLotteryResultsResponse';
export { $GetOptInProductsRequest } from './schemas/$GetOptInProductsRequest';
export { $GetOptInProductsResponse } from './schemas/$GetOptInProductsResponse';
export { $GetPersonalJackpotRequest } from './schemas/$GetPersonalJackpotRequest';
export { $GetPersonalJackpotResponse } from './schemas/$GetPersonalJackpotResponse';
export { $GetSignupCountriesAndStatesRequest } from './schemas/$GetSignupCountriesAndStatesRequest';
export { $GetSignupCountriesAndStatesResponse } from './schemas/$GetSignupCountriesAndStatesResponse';
export { $GetSignupCountriesRequest } from './schemas/$GetSignupCountriesRequest';
export { $GetSignupCountriesResponse } from './schemas/$GetSignupCountriesResponse';
export { $GetSkipOtpCheckRequest } from './schemas/$GetSkipOtpCheckRequest';
export { $GetSkipOtpCheckResponse } from './schemas/$GetSkipOtpCheckResponse';
export { $GetTemporaryTokenRequest } from './schemas/$GetTemporaryTokenRequest';
export { $GetTemporaryTokenResponse } from './schemas/$GetTemporaryTokenResponse';
export { $GetVisitedPromotionsRequest } from './schemas/$GetVisitedPromotionsRequest';
export { $GetVisitedPromotionsResponse } from './schemas/$GetVisitedPromotionsResponse';
export { $GoldCoinGeneratorSettings } from './schemas/$GoldCoinGeneratorSettings';
export { $InvitationBonusInfo } from './schemas/$InvitationBonusInfo';
export { $InvitationInfo } from './schemas/$InvitationInfo';
export { $InvitationRewardPolicy } from './schemas/$InvitationRewardPolicy';
export { $JackpotAccountFreeContribution } from './schemas/$JackpotAccountFreeContribution';
export { $JackpotInfo } from './schemas/$JackpotInfo';
export { $JackpotPreferences } from './schemas/$JackpotPreferences';
export { $JsonNode } from './schemas/$JsonNode';
export { $KYCInfo } from './schemas/$KYCInfo';
export { $KYCStatus } from './schemas/$KYCStatus';
export { $LegalRule } from './schemas/$LegalRule';
export { $LegalRuleSettings } from './schemas/$LegalRuleSettings';
export { $LevelInfo } from './schemas/$LevelInfo';
export { $LocationInfo } from './schemas/$LocationInfo';
export { $LowOnCoinsRequest } from './schemas/$LowOnCoinsRequest';
export { $LowOnCoinsResponse } from './schemas/$LowOnCoinsResponse';
export { $ManualBonusInfo } from './schemas/$ManualBonusInfo';
export { $OtpModalTriggeredRequest } from './schemas/$OtpModalTriggeredRequest';
export { $OtpModalTriggeredResponse } from './schemas/$OtpModalTriggeredResponse';
export { $PasswordEnforcementPolicy } from './schemas/$PasswordEnforcementPolicy';
export { $PersonalInfo } from './schemas/$PersonalInfo';
export { $platform } from './schemas/$platform';
export { $ProductInfo } from './schemas/$ProductInfo';
export { $ProductInfoTags } from './schemas/$ProductInfoTags';
export { $ProductModes } from './schemas/$ProductModes';
export { $Promo } from './schemas/$Promo';
export { $qBrandName } from './schemas/$qBrandName';
export { $qPlatform } from './schemas/$qPlatform';
export { $RedeemMoneyPolicy } from './schemas/$RedeemMoneyPolicy';
export { $RemoveAccountFavouriteGameRequest } from './schemas/$RemoveAccountFavouriteGameRequest';
export { $RemoveAccountFavouriteGameResponse } from './schemas/$RemoveAccountFavouriteGameResponse';
export { $ResendEmailVerificationRequest } from './schemas/$ResendEmailVerificationRequest';
export { $ResendEmailVerificationResponse } from './schemas/$ResendEmailVerificationResponse';
export { $ResetPasswordManuallyRequest } from './schemas/$ResetPasswordManuallyRequest';
export { $ResetPasswordManuallyResponse } from './schemas/$ResetPasswordManuallyResponse';
export { $RuleType } from './schemas/$RuleType';
export { $SaveJackpotOptInRequest } from './schemas/$SaveJackpotOptInRequest';
export { $SaveJackpotOptInResponse } from './schemas/$SaveJackpotOptInResponse';
export { $SendSignInByTokenEmailRequest } from './schemas/$SendSignInByTokenEmailRequest';
export { $SendSignInByTokenEmailResponse } from './schemas/$SendSignInByTokenEmailResponse';
export { $SendWelcomeEmailRequest } from './schemas/$SendWelcomeEmailRequest';
export { $SendWelcomeEmailResponse } from './schemas/$SendWelcomeEmailResponse';
export { $SetAccountAttributionInfoRequest } from './schemas/$SetAccountAttributionInfoRequest';
export { $SetAccountAttributionInfoResponse } from './schemas/$SetAccountAttributionInfoResponse';
export { $SetAccountInfoRequest } from './schemas/$SetAccountInfoRequest';
export { $SetAccountInfoResponse } from './schemas/$SetAccountInfoResponse';
export { $SetAccountModeRequest } from './schemas/$SetAccountModeRequest';
export { $SetAccountModeResponse } from './schemas/$SetAccountModeResponse';
export { $SetKYCInfoRequest } from './schemas/$SetKYCInfoRequest';
export { $SetKYCInfoResponse } from './schemas/$SetKYCInfoResponse';
export { $SetLocationInfoRequest } from './schemas/$SetLocationInfoRequest';
export { $SetLocationInfoResponse } from './schemas/$SetLocationInfoResponse';
export { $SetPushInfoRequest } from './schemas/$SetPushInfoRequest';
export { $SetPushInfoResponse } from './schemas/$SetPushInfoResponse';
export { $UpdateKYCInfoRequest } from './schemas/$UpdateKYCInfoRequest';
export { $UpdateKYCInfoResponse } from './schemas/$UpdateKYCInfoResponse';
export { $UpdateVisitedPromotionsRequest } from './schemas/$UpdateVisitedPromotionsRequest';
export { $UpdateVisitedPromotionsResponse } from './schemas/$UpdateVisitedPromotionsResponse';
export { $X_Chk } from './schemas/$X_Chk';
export { $X_Message_Id } from './schemas/$X_Message_Id';
export { $X_Timestamp } from './schemas/$X_Timestamp';
export { $xMessageId } from './schemas/$xMessageId';
export { $xStatus } from './schemas/$xStatus';
export { $xStatusText } from './schemas/$xStatusText';
export { $xTook } from './schemas/$xTook';

export { CrmService } from './services/CrmService';
