/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetAccountAttributionInfoRequest = {
    properties: {
        advertisingId: {
            type: 'string',
        },
        appVersion: {
            type: 'string',
        },
        osVersion: {
            type: 'string',
        },
    },
} as const;
