import type { IServiceExtension, IServiceInstanceOptions } from '../types';

export default class PlatformExtension implements IServiceExtension<string> {
  public constructor(private readonly options: IServiceInstanceOptions) {}

  public name: string = 'platform';
  getData = (): Promise<string> | string => {
    return this.options.platform || 'web';
  };
}
