/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptDailyBonusRequest } from '../models/AcceptDailyBonusRequest';
import type { AcceptDailyBonusResponse } from '../models/AcceptDailyBonusResponse';
import type { AcceptFreeGoldCoinsRequest } from '../models/AcceptFreeGoldCoinsRequest';
import type { AcceptFreeGoldCoinsResponse } from '../models/AcceptFreeGoldCoinsResponse';
import type { AcceptInvitationBonusRequest } from '../models/AcceptInvitationBonusRequest';
import type { AcceptInvitationBonusResponse } from '../models/AcceptInvitationBonusResponse';
import type { AcceptManualBonusRequest } from '../models/AcceptManualBonusRequest';
import type { AcceptManualBonusResponse } from '../models/AcceptManualBonusResponse';
import type { AcceptPersonalJackpotRequest } from '../models/AcceptPersonalJackpotRequest';
import type { AcceptPersonalJackpotResponse } from '../models/AcceptPersonalJackpotResponse';
import type { AcceptRulesRequest } from '../models/AcceptRulesRequest';
import type { AcceptRulesResponse } from '../models/AcceptRulesResponse';
import type { AcceptSweepstakeIntroRequest } from '../models/AcceptSweepstakeIntroRequest';
import type { AcceptSweepstakeIntroResponse } from '../models/AcceptSweepstakeIntroResponse';
import type { AddAccountFavouriteGameRequest } from '../models/AddAccountFavouriteGameRequest';
import type { AddAccountFavouriteGameResponse } from '../models/AddAccountFavouriteGameResponse';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ChangePasswordResponse } from '../models/ChangePasswordResponse';
import type { ChangeSubscriptionPreferencesRequest } from '../models/ChangeSubscriptionPreferencesRequest';
import type { ChangeSubscriptionPreferencesResponse } from '../models/ChangeSubscriptionPreferencesResponse';
import type { ConfirmPhoneNumberVerificationRequest } from '../models/ConfirmPhoneNumberVerificationRequest';
import type { ConfirmPhoneNumberVerificationResponse } from '../models/ConfirmPhoneNumberVerificationResponse';
import type { CreatePhoneNumberVerificationRequest } from '../models/CreatePhoneNumberVerificationRequest';
import type { CreatePhoneNumberVerificationResponse } from '../models/CreatePhoneNumberVerificationResponse';
import type { CreatePostalCodeRequest } from '../models/CreatePostalCodeRequest';
import type { CreatePostalCodeResponse } from '../models/CreatePostalCodeResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { GetAccountBonusesRequest } from '../models/GetAccountBonusesRequest';
import type { GetAccountBonusesResponse } from '../models/GetAccountBonusesResponse';
import type { GetAccountFreeSpinsRequest } from '../models/GetAccountFreeSpinsRequest';
import type { GetAccountFreeSpinsResponse } from '../models/GetAccountFreeSpinsResponse';
import type { GetAccountInfoRequest } from '../models/GetAccountInfoRequest';
import type { GetAccountInfoResponse } from '../models/GetAccountInfoResponse';
import type { GetAccountInvitationsRequest } from '../models/GetAccountInvitationsRequest';
import type { GetAccountInvitationsResponse } from '../models/GetAccountInvitationsResponse';
import type { GetAccountJackpotInfoRequest } from '../models/GetAccountJackpotInfoRequest';
import type { GetAccountJackpotInfoResponse } from '../models/GetAccountJackpotInfoResponse';
import type { GetAccountSettingsRequest } from '../models/GetAccountSettingsRequest';
import type { GetAccountSettingsResponse } from '../models/GetAccountSettingsResponse';
import type { GetBannersRequest } from '../models/GetBannersRequest';
import type { GetBannersResponse } from '../models/GetBannersResponse';
import type { GetFreeGoldCoinsStatusRequest } from '../models/GetFreeGoldCoinsStatusRequest';
import type { GetFreeGoldCoinsStatusResponse } from '../models/GetFreeGoldCoinsStatusResponse';
import type { GetJackpotInfoRequest } from '../models/GetJackpotInfoRequest';
import type { GetJackpotInfoResponse } from '../models/GetJackpotInfoResponse';
import type { GetJackpotOptInRequest } from '../models/GetJackpotOptInRequest';
import type { GetJackpotOptInResponse } from '../models/GetJackpotOptInResponse';
import type { GetJackpotTotalsRequest } from '../models/GetJackpotTotalsRequest';
import type { GetJackpotTotalsResponse } from '../models/GetJackpotTotalsResponse';
import type { GetKYCInfoRequest } from '../models/GetKYCInfoRequest';
import type { GetKYCInfoResponse } from '../models/GetKYCInfoResponse';
import type { GetLiveChatSettingsRequest } from '../models/GetLiveChatSettingsRequest';
import type { GetLiveChatSettingsResponse } from '../models/GetLiveChatSettingsResponse';
import type { GetLotteryResultsRequest } from '../models/GetLotteryResultsRequest';
import type { GetLotteryResultsResponse } from '../models/GetLotteryResultsResponse';
import type { GetOptInProductsRequest } from '../models/GetOptInProductsRequest';
import type { GetOptInProductsResponse } from '../models/GetOptInProductsResponse';
import type { GetPersonalJackpotRequest } from '../models/GetPersonalJackpotRequest';
import type { GetPersonalJackpotResponse } from '../models/GetPersonalJackpotResponse';
import type { GetSignupCountriesAndStatesRequest } from '../models/GetSignupCountriesAndStatesRequest';
import type { GetSignupCountriesAndStatesResponse } from '../models/GetSignupCountriesAndStatesResponse';
import type { GetSignupCountriesRequest } from '../models/GetSignupCountriesRequest';
import type { GetSignupCountriesResponse } from '../models/GetSignupCountriesResponse';
import type { GetSkipOtpCheckRequest } from '../models/GetSkipOtpCheckRequest';
import type { GetSkipOtpCheckResponse } from '../models/GetSkipOtpCheckResponse';
import type { GetTemporaryTokenRequest } from '../models/GetTemporaryTokenRequest';
import type { GetTemporaryTokenResponse } from '../models/GetTemporaryTokenResponse';
import type { GetVisitedPromotionsRequest } from '../models/GetVisitedPromotionsRequest';
import type { GetVisitedPromotionsResponse } from '../models/GetVisitedPromotionsResponse';
import type { LowOnCoinsRequest } from '../models/LowOnCoinsRequest';
import type { LowOnCoinsResponse } from '../models/LowOnCoinsResponse';
import type { OtpModalTriggeredRequest } from '../models/OtpModalTriggeredRequest';
import type { OtpModalTriggeredResponse } from '../models/OtpModalTriggeredResponse';
import type { qBrandName } from '../models/qBrandName';
import type { qPlatform } from '../models/qPlatform';
import type { RemoveAccountFavouriteGameRequest } from '../models/RemoveAccountFavouriteGameRequest';
import type { RemoveAccountFavouriteGameResponse } from '../models/RemoveAccountFavouriteGameResponse';
import type { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import type { ResendEmailVerificationResponse } from '../models/ResendEmailVerificationResponse';
import type { ResetPasswordManuallyRequest } from '../models/ResetPasswordManuallyRequest';
import type { ResetPasswordManuallyResponse } from '../models/ResetPasswordManuallyResponse';
import type { SaveJackpotOptInRequest } from '../models/SaveJackpotOptInRequest';
import type { SaveJackpotOptInResponse } from '../models/SaveJackpotOptInResponse';
import type { SendSignInByTokenEmailRequest } from '../models/SendSignInByTokenEmailRequest';
import type { SendSignInByTokenEmailResponse } from '../models/SendSignInByTokenEmailResponse';
import type { SendWelcomeEmailRequest } from '../models/SendWelcomeEmailRequest';
import type { SendWelcomeEmailResponse } from '../models/SendWelcomeEmailResponse';
import type { SetAccountAttributionInfoRequest } from '../models/SetAccountAttributionInfoRequest';
import type { SetAccountAttributionInfoResponse } from '../models/SetAccountAttributionInfoResponse';
import type { SetAccountInfoRequest } from '../models/SetAccountInfoRequest';
import type { SetAccountInfoResponse } from '../models/SetAccountInfoResponse';
import type { SetAccountModeRequest } from '../models/SetAccountModeRequest';
import type { SetAccountModeResponse } from '../models/SetAccountModeResponse';
import type { SetKYCInfoRequest } from '../models/SetKYCInfoRequest';
import type { SetKYCInfoResponse } from '../models/SetKYCInfoResponse';
import type { SetLocationInfoRequest } from '../models/SetLocationInfoRequest';
import type { SetLocationInfoResponse } from '../models/SetLocationInfoResponse';
import type { SetPushInfoRequest } from '../models/SetPushInfoRequest';
import type { SetPushInfoResponse } from '../models/SetPushInfoResponse';
import type { UpdateKYCInfoRequest } from '../models/UpdateKYCInfoRequest';
import type { UpdateKYCInfoResponse } from '../models/UpdateKYCInfoResponse';
import type { UpdateVisitedPromotionsRequest } from '../models/UpdateVisitedPromotionsRequest';
import type { UpdateVisitedPromotionsResponse } from '../models/UpdateVisitedPromotionsResponse';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CrmService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptDailyBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptDailyBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptDailyBonusRequest,
        xChk?: string,
    ): CancelablePromise<AcceptDailyBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptDailyBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptFreeGoldCoinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptFreeGoldCoins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptFreeGoldCoinsRequest,
        xChk?: string,
    ): CancelablePromise<AcceptFreeGoldCoinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptFreeGoldCoinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptInvitationBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptInvitationBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptInvitationBonusRequest,
        xChk?: string,
    ): CancelablePromise<AcceptInvitationBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptInvitationBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptManualBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptManualBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptManualBonusRequest,
        xChk?: string,
    ): CancelablePromise<AcceptManualBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptManualBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptRulesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptRules(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptRulesRequest,
        xChk?: string,
    ): CancelablePromise<AcceptRulesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptRulesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptSweepstakeIntroResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptSweepstakeIntro(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptSweepstakeIntroRequest,
        xChk?: string,
    ): CancelablePromise<AcceptSweepstakeIntroResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptSweepstakeIntroRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AddAccountFavouriteGameResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public addAccountFavouriteGame(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AddAccountFavouriteGameRequest,
        xChk?: string,
    ): CancelablePromise<AddAccountFavouriteGameResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AddAccountFavouriteGameRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ChangePasswordResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public changePassword(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ChangePasswordRequest,
        xChk?: string,
    ): CancelablePromise<ChangePasswordResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ChangePasswordRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ChangeSubscriptionPreferencesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public changeSubscriptionPreferences(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ChangeSubscriptionPreferencesRequest,
        xChk?: string,
    ): CancelablePromise<ChangeSubscriptionPreferencesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ChangeSubscriptionPreferencesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ConfirmPhoneNumberVerificationResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public confirmPhoneNumberVerification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ConfirmPhoneNumberVerificationRequest,
        xChk?: string,
    ): CancelablePromise<ConfirmPhoneNumberVerificationResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ConfirmPhoneNumberVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreatePhoneNumberVerificationResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createPhoneNumberVerification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreatePhoneNumberVerificationRequest,
        xChk?: string,
    ): CancelablePromise<CreatePhoneNumberVerificationResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreatePhoneNumberVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreatePostalCodeResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createPostalCode(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreatePostalCodeRequest,
        xChk?: string,
    ): CancelablePromise<CreatePostalCodeResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreatePostalCodeRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountBonusesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountBonuses(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountBonusesRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountBonusesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountBonusesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountFreeSpinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountFreeSpins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountFreeSpinsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountFreeSpinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountFreeSpinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountInvitationsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountInvitations(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountInvitationsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountInvitationsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountInvitationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountSettingsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountSettings(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountSettingsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountSettingsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountSettingsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetBannersResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getBanners(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetBannersRequest,
        xChk?: string,
    ): CancelablePromise<GetBannersResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetBannersRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetFreeGoldCoinsStatusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getFreeGoldCoinsStatus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetFreeGoldCoinsStatusRequest,
        xChk?: string,
    ): CancelablePromise<GetFreeGoldCoinsStatusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetFreeGoldCoinsStatusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetKYCInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getKycInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetKYCInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetKYCInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetLiveChatSettingsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getLiveChatSettings(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetLiveChatSettingsRequest,
        xChk?: string,
    ): CancelablePromise<GetLiveChatSettingsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetLiveChatSettingsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetLotteryResultsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getLotteryResults(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetLotteryResultsRequest,
        xChk?: string,
    ): CancelablePromise<GetLotteryResultsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetLotteryResultsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetOptInProductsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getOptInProducts(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetOptInProductsRequest,
        xChk?: string,
    ): CancelablePromise<GetOptInProductsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetOptInProductsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSignupCountriesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getSignupCountries(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetSignupCountriesRequest,
        xChk?: string,
    ): CancelablePromise<GetSignupCountriesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSignupCountriesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSignupCountriesAndStatesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getSignupCountriesAndStates(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetSignupCountriesAndStatesRequest,
        xChk?: string,
    ): CancelablePromise<GetSignupCountriesAndStatesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSignupCountriesAndStatesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSkipOtpCheckResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getSkipOtpCheck(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetSkipOtpCheckRequest,
        xChk?: string,
    ): CancelablePromise<GetSkipOtpCheckResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSkipOtpCheckRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetTemporaryTokenResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getTemporaryToken(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetTemporaryTokenRequest,
        xChk?: string,
    ): CancelablePromise<GetTemporaryTokenResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetTemporaryTokenRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetVisitedPromotionsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getVisitedPromotions(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetVisitedPromotionsRequest,
        xChk?: string,
    ): CancelablePromise<GetVisitedPromotionsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetVisitedPromotionsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns LowOnCoinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public lowOnCoins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: LowOnCoinsRequest,
        xChk?: string,
    ): CancelablePromise<LowOnCoinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/LowOnCoinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns OtpModalTriggeredResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public otpModalTriggered(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: OtpModalTriggeredRequest,
        xChk?: string,
    ): CancelablePromise<OtpModalTriggeredResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/OtpModalTriggeredRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns RemoveAccountFavouriteGameResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public removeAccountFavouriteGame(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: RemoveAccountFavouriteGameRequest,
        xChk?: string,
    ): CancelablePromise<RemoveAccountFavouriteGameResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/RemoveAccountFavouriteGameRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ResendEmailVerificationResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public resendEmailVerification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ResendEmailVerificationRequest,
        xChk?: string,
    ): CancelablePromise<ResendEmailVerificationResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ResendEmailVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ResetPasswordManuallyResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public resetPasswordManually(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ResetPasswordManuallyRequest,
        xChk?: string,
    ): CancelablePromise<ResetPasswordManuallyResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ResetPasswordManuallyRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SendSignInByTokenEmailResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public sendSignInByTokenEmail(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SendSignInByTokenEmailRequest,
        xChk?: string,
    ): CancelablePromise<SendSignInByTokenEmailResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SendSignInByTokenEmailRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SendWelcomeEmailResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public sendWelcomeEmail(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SendWelcomeEmailRequest,
        xChk?: string,
    ): CancelablePromise<SendWelcomeEmailResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SendWelcomeEmailRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountAttributionInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountAttributionInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountAttributionInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountAttributionInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountAttributionInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountModeResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountMode(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountModeRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountModeResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountModeRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetKYCInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setKycInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetKYCInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetKYCInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetLocationInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setLocationInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetLocationInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetLocationInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetLocationInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetPushInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setPushInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetPushInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetPushInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetPushInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateKYCInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateKycInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: UpdateKYCInfoRequest,
        xChk?: string,
    ): CancelablePromise<UpdateKYCInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateVisitedPromotionsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateVisitedPromotions(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: UpdateVisitedPromotionsRequest,
        xChk?: string,
    ): CancelablePromise<UpdateVisitedPromotionsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateVisitedPromotionsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptPersonalJackpotResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptPersonalJackpot(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptPersonalJackpotRequest,
        xChk?: string,
    ): CancelablePromise<AcceptPersonalJackpotResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptPersonalJackpotRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountJackpotInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountJackpotInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountJackpotInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountJackpotInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountJackpotInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotOptInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotOptIn(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotOptInRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotOptInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotOptInRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotTotalsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotTotals(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotTotalsRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotTotalsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotTotalsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetPersonalJackpotResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getPersonalJackpot(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetPersonalJackpotRequest,
        xChk?: string,
    ): CancelablePromise<GetPersonalJackpotResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetPersonalJackpotRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SaveJackpotOptInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public saveJackpotOptIn(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SaveJackpotOptInRequest,
        xChk?: string,
    ): CancelablePromise<SaveJackpotOptInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SaveJackpotOptInRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
