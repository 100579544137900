/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $JackpotInfo = {
    properties: {
        code: {
            type: 'Enum',
            isRequired: true,
        },
        prevAmount: {
            type: 'number',
            isRequired: true,
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        updatedAt: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        lastWinAt: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        endAt: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
    },
} as const;
