/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetAccountModeResponse = {
    properties: {
        sweepstake: {
            type: 'boolean',
        },
        account: {
            type: 'AccountInfo',
        },
    },
} as const;
