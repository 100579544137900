/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CountryAndStates = {
    properties: {
        country: {
            type: 'string',
        },
        allowedStates: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        blockedStates: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
