import type { TypedThunk } from 'src/store/types'
import { actions } from './slice'

export const { openSnackbar, clearSnackbarState, closeSnackbar: closeSnackbarAcion } = actions

export const closeSnackbar = (): TypedThunk => (dispatch) => {
  dispatch(closeSnackbarAcion())

  setTimeout(() => {
    dispatch(clearSnackbarState())
  }, 500)
}
