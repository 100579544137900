/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetJackpotTotalsResponse = {
    properties: {
        currency: {
            type: 'string',
        },
        personalTotal: {
            type: 'string',
        },
        thisWeekTotal: {
            type: 'string',
        },
        nextWeekTotal: {
            type: 'string',
        },
        personalExpire: {
            type: 'string',
            format: 'date-time',
        },
        thisWeekExpire: {
            type: 'string',
            format: 'date-time',
        },
        nextWeekExpire: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
