import type { IServiceExtension, IServiceInstanceOptions } from '../types';

export default class XChkExtension implements IServiceExtension<boolean | undefined> {
  public constructor(private readonly options: IServiceInstanceOptions) {}

  public name: string = 'xChk';
  getData = (): Promise<boolean> | boolean => {
    return this.options.xChk || undefined;
  };
}
