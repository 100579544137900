/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ProductModes {
    GOLD = 'gold',
    SWEEPSTAKE = 'sweepstake',
    FIAT = 'fiat',
    GOLD_SWEEPSTAKE = 'gold_sweepstake',
    GOLD_FIAT = 'gold_fiat',
    FREE = 'free',
}
