import { v4 as uuid } from 'uuid'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { DialogNames, DialogState, ModalOptions } from 'store/types'
import { openRedeemHistoryDialogAction } from './actions'
import { replaceDialogUtil } from './utils'

const initialState: DialogState = {
  // last in first out
  stack: [],
  isMenuOpened: false,
  lightBackdropMode: false,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (
      state,
      action: PayloadAction<{
        modalName: DialogNames
        dialogProps?: any
        options?: ModalOptions
        lightBackdropMode?: boolean
      }>
    ) => {
      if (
        !(
          state.stack.some((modal) => modal.modalName === action.payload.modalName) &&
          action.payload.dialogProps?.singletone
        )
      ) {
        state.stack = state.stack.concat({
          modalName: action.payload.modalName,
          dialogProps: action.payload.dialogProps,
          options: action.payload.options,
          id: uuid(),
        })
        state.lightBackdropMode = action.payload.lightBackdropMode
      }
    },

    replaceDialog: (
      state,
      action: PayloadAction<{ modalName: DialogNames; dialogProps?: any; options?: ModalOptions }>
    ) => {
      state.stack = replaceDialogUtil(state, action)
    },

    removeDialogByName: (state, action: PayloadAction<{ modalName: DialogNames }>) => {
      state.stack = state.stack.filter((i) => {
        return i.modalName !== action.payload.modalName
      })
    },

    closeLatestDialog: (state) => {
      state.stack = state.stack.slice(0, -1)
      state.lightBackdropMode = false
    },

    closeAllDialogs: (state) => {
      state.stack = []
      state.lightBackdropMode = false
    },

    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpened = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openRedeemHistoryDialogAction, (state, action) => {
      state.stack = replaceDialogUtil(state, {
        type: action.type,
        payload: { modalName: 'REDEEM_HISTORY_DIALOG' },
      })
    })
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof dialogSlice.actions>>

export const { actions } = dialogSlice
