/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountsMode {
    DEFAULT = 'default',
    GOLD = 'gold',
    GOLD_FREE = 'gold_free',
    SWEEPSTAKE = 'sweepstake',
    SWEEPSTAKE_PREVIEW = 'sweepstake_preview',
    SWEEPSTAKE_PREVIEW_WEB = 'sweepstake_preview_web',
}
