/* eslint-disable */
import { IServiceInstanceOptions, LastInTuple, ValidateResponse } from './types'
import { createExtensions } from './extensions'
import {
  OpenAPI,
  Api,
  CrmService,
} from './client';
const externalServices = [CrmService];

export enum Definition {
  X_MESSAGE_ID = 'xMessageId',
  X_TIMESTAMP = 'xTimestamp',
  BRAND_NAME = 'brandName',
  PLATFORM = 'platform',
  REQUEST_BODY = 'requestBody',
  X_CHK = 'xChk',
}

type ApiServiceType = Omit<CrmService , 'httpRequest'>;

type RequestBody<T extends unknown[]> = LastInTuple<T>;

type ApiServiceTypeWithRequestBody = {
  [K in keyof Omit<ApiServiceType, 'prototype'>]: (
    requestBody: RequestBody<Parameters<ApiServiceType[K]>>,
  ) => ReturnType<ApiServiceType[K]>;
};

export const createApiClient = (
  options: IServiceInstanceOptions,
): ApiServiceTypeWithRequestBody => {
  OpenAPI.BASE = options.url;
  OpenAPI.HEADERS = {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': options.url || '*',
    'user-agent': options.userAgent || undefined,
    ...(options?.headers || {}),
  }
  OpenAPI.WITH_CREDENTIALS = true;

  const api = new Api(OpenAPI);

  // keys of service
  const serviceNames = externalServices.map((item) => item.name);
  
  const servicePropertyByServiceTypeMap = Object.entries(api).reduce(
    (acc: Record<string, keyof Api>, [key, value]: [keyof Api, string]) => {
      if (key === 'request') {
        return acc;
      }

      if (typeof value?.constructor?.name === 'string') {
        acc[value.constructor.name] = key
      }

      return acc
    }, {} as Record<string, keyof Api>
  )

  const methodsByService = serviceNames.reduce((acc, curr) => {
    const service = servicePropertyByServiceTypeMap[curr];
    // @ts-ignore need to extract all method from prototype
    const methods = Object.keys(Object.getOwnPropertyDescriptors(api[service as keyof Api].__proto__));

    methods.forEach((item) => {
      acc[item] = curr;
    })

    return acc;
  }, {} as Record<string, string>)


  let worker: Worker | null  = null;

  return new Proxy(api, {
    get(targetService, propKey: string) {
      if (methodsByService[propKey as string]) {
        const service = methodsByService[propKey as string]
        return new Proxy(function (){}, {
          apply: async (target, thisArg: unknown, argArray: unknown[]): Promise<unknown> => {
            const [requestBody] = argArray;
            const serviceExtension: string[] = Object.values(Definition);
            const extensions = createExtensions(options);
            const extensionsData = serviceExtension.map((item: string) => {
              if (item === Definition.REQUEST_BODY) {
                return requestBody;
              }

              return extensions[item].getData();
            });

            const serviceInstance = api[service as keyof Api]

            // @ts-ignore
            const result = await serviceInstance[propKey](...extensionsData, requestBody)
            
            
            if (options.onValidationError) {
              if (!worker) {
                worker = new Worker(new URL('./validateWorker', import.meta.url));
                worker.onmessage = (e: MessageEvent<ValidateResponse<unknown>>) => {
                options.onValidationError?.(e.data);
              }
            }
            worker?.postMessage({ requestName: propKey, data: result });
            }
            
            return result
          }
        })
      }
    },
  }) as unknown as ApiServiceTypeWithRequestBody;
}
