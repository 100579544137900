/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ChangeSubscriptionPreferencesRequest = {
    properties: {
        doNotSendEmails: {
            type: 'boolean',
        },
        doNotSendPushes: {
            type: 'boolean',
        },
    },
} as const;
