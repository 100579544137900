/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccountJackpotInfoResponse = {
    properties: {
        info: {
            type: 'array',
            contains: {
                type: 'AccountJackpotInfo',
            },
        },
        contributions: {
            type: 'array',
            contains: {
                type: 'AccountPersonalJackpotContribution',
            },
        },
        freeContributions: {
            type: 'array',
            contains: {
                type: 'JackpotAccountFreeContribution',
            },
        },
        wins: {
            type: 'array',
            contains: {
                type: 'AccountJackpotWin',
            },
        },
    },
} as const;
