
import type { IServiceExtension, IServiceInstanceOptions } from '../types';

import BrandName from './brandName.extension';
import Platform from './platform.extension';
import RequestId from './requestId.extension';
import Timestamp from './timestamp.extension';
import XChk from './xChk.extension';

export const createExtensions = (
  options: IServiceInstanceOptions,
): Record<string, IServiceExtension<unknown>> => {
  const extensions = [
         new BrandName(options),
     new Platform(options),
     new RequestId(options),
     new Timestamp(options),
     new XChk(options),
  ];

  return extensions.reduce(
    (acc, curr) => {
      acc[curr.name] = curr;

      return acc;
    },
    {} as Record<string, IServiceExtension<unknown>>,
  );
};
 