/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AppliedRules = {
    properties: {
        srVersion: {
            type: 'boolean',
            isRequired: true,
        },
        tcVersion: {
            type: 'boolean',
            isRequired: true,
        },
        ppVersion: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
