import { gateway, gatewayError } from '@patrianna/gateway'
import { openDialog } from 'store/modules/dialog/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { createApiClient } from 'temp/api'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import type { ValidateResponse } from 'temp/api/types'

const gatewayInstance = gateway.create({
  baseURL: process.env.GATEWAY,
  baseFetchURL: process.env.SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
})

export const gatewayErrorInstance = gatewayError.setupActions({ openDialog, openSnackbar })

export default gatewayInstance

export const api = createApiClient({
  url: isBrowser() ? process.env.GATEWAY : process.env.SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
  userAgent: isBrowser() ? undefined : process.env.VERCEL_USER_AGENT || 'VERCEL_USER_AGENT undefined',
  onValidationError: (payload: ValidateResponse) => {
    // @todo add sentry
    if (process.env.PROFILE === 'dev') {
      console.warn(`[API VALIDATION ERROR]: ${payload.request}`, payload.errors)
    }
  },
})
