import type { IServiceExtension, IServiceInstanceOptions } from '../types';

export default class BrandNameExtension implements IServiceExtension<string> {
  public constructor(private readonly options: IServiceInstanceOptions) {}

  public name: string = 'brandName';
  getData = (): Promise<string> | string => {
    return this.options.brandName;
  };
}
