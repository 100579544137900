/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SetKYCInfoResponse = {
    nextKYC: string;
    transactionReference: string;
    redirectUrl?: string;
    scanReference?: string;
    clientRedirectUrl?: string;
    status?: string;
    errorReason?: string;
    provider: SetKYCInfoResponse.provider;
};
export namespace SetKYCInfoResponse {
    export enum provider {
        JUMIO = 'jumio',
        VERIFF = 'veriff',
        MANUAL = 'manual',
    }
}

