/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BannerInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        title: {
            type: 'string',
            isRequired: true,
        },
        segment: {
            type: 'string',
        },
        segmentTags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        priority: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        ctaUrl: {
            type: 'string',
        },
        bannerImageUrl: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
