/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InvitationBonusInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        goldAmount: {
            type: 'number',
        },
        sweepstakeAmount: {
            type: 'number',
        },
        at: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        account: {
            type: 'AccountInfo',
        },
        actor: {
            type: 'AccountInfo',
        },
        category: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
