import { v4 as uuid } from 'uuid'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { DialogNames, ModalOptions } from 'store/types'
import type { DialogState } from 'store/types'

export const replaceDialogUtil = (
  state: DialogState,
  action: PayloadAction<{ modalName: DialogNames; dialogProps?: any; options?: ModalOptions }>
) => {
  const { stack } = state
  const { modalName, dialogProps, options } = action.payload

  const idx = stack.findIndex((it) => it.modalName === modalName)

  if (idx !== -1) {
    return stack.concat({ modalName, dialogProps, options, id: uuid() }).filter((_, index) => index !== idx)
  }

  return stack.concat({
    modalName: action.payload.modalName,
    dialogProps: action.payload.dialogProps,
    options: action.payload.options,
    id: uuid(),
  })
}
