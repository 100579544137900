/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveJackpotOptInRequest = {
    properties: {
        optIn: {
            type: 'boolean',
            isRequired: true,
        },
        currency: {
            type: 'string',
            isRequired: true,
        },
        amount: {
            type: 'number',
        },
    },
} as const;
